<script setup lang="ts">
const {
	locale: currentLocaleCode, setLocale, locales,
} = useI18n();

const currentLocale = ref(locales.value.filter((localeObject) => localeObject.code === currentLocaleCode.value));

const switchLanguage = (code: string) => {
	setLocale(code);
};
</script>

<template>
	<USelectMenu
		v-model="currentLocale[0]"
		selected-icon="i-heroicons-check-16-solid"
		:icon="undefined"
		:options="locales"
		:ui="{
			base: 'uppercase !cursor-pointer',
		}"
		:ui-menu="{
			base: 'w-fit',
			option: {
				base: 'cursor-pointer right-0 min-w-[150px]',
				selectedIcon: {
					base: 'text-amber-400',
				},
			},
		}"
		:popper="{
			offsetSkid: -116,
		}"
		:trailing-icon="undefined"
		@change="(value) => switchLanguage(value.code)"
	>
		<template #label>
			<UIcon
				name="i-heroicons-globe-alt"
				class="text-2xl text-gray-500"
			/>
		</template>
		<template #option="{ option: locale }">
			<div
				class="uppercase font-semibold text-xs flex gap-1"
				:class="{
					'text-amber-400': currentLocale[0]?.code === locale.code,
				}"
			>
				<span>{{ locale.name }}</span>
				<span>({{ locale.code }})</span>
			</div>
		</template>
	</USelectMenu>
</template>
